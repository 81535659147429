import store from "@/store"
import ConstConfig from "@/config/ConstConfig";

let sourceIndex = 0;

// URL地址解析
export function getJsUrl(url){
	var pos,str,para,parastr; 
	var array =[]
	str = location.href; 
	parastr = str.split("?")[1]; 
	var arr = parastr.split("&");
	for (var i=0;i<arr.length;i++){
        array[arr[i].split("=")[0]]=arr[i].split("=")[1];
    }
    return array;
}

// 复制
export function copy(data) {
    let OrderNumber = data;
    let newInput = document.createElement("input");
    newInput.value = OrderNumber;
    document.body.appendChild(newInput);
    newInput.select();
    document.execCommand("Copy");
    newInput.remove();
}

// 判断是否是app
export function getIsApp() {
    // let ua = navigator.userAgent.toLowerCase();
    // // alert("ua==>" + ua);
    // let app = ua.match(/android|adr/i);
    // alert(app);
    let app = localStorage.getItem("isApp");
    if (app === "true") {
        store.state.isApp = true;
        return true;
    }
    store.state.isApp = false;
    return false;
}

/**获取链接域名端口号*/
export function getBaseUrl () {
/**获取当前地址+拼接*/
    let baseUrl =location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "") + "/";
    return baseUrl
}

/**转换静态资源url */
export function covertSourceUrl(sourceUrl) {
    let newSource = ConstConfig.SOURCES_URL[sourceIndex];
    const reg = /((https|http|ftp|file):\/\/)([A-Za-z0-9\-.]+)(:[0-9]+){0,1}/g;
    let arr = sourceUrl.match(reg);
    sourceUrl = sourceUrl.replace((arr&&arr[0])?arr[0]:"https://d39aeoq8vaymye.cloudfront.net", newSource);
    sourceIndex ++;
    if (sourceIndex == ConstConfig.SOURCES_URL.length) {
        sourceIndex = 0;
    }
    return sourceUrl;
}

export default {
    copy,
    getIsApp,
    getBaseUrl,
    covertSourceUrl,
}
<template>
  <div class="vip-wrap">
    <span class="grade">
      <img class="pre" :src="require('@/assets/vip1/VIP-Arc-'+ $store.state.myLevel +'.png')">
    </span>
  </div>
</template>

<script>
export default {
  props: {
    grade: {
      type: String,
    },
  },
  name: "VipTag",
  data() {
    return {
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
@import "./pc.less";
@media only screen and (max-width: @screen-width4) {
  @import "./h5.less";
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-check-wrap"},[_c('a-modal',{attrs:{"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"footer":null,"closable":false,"maskClosable":false,"width":"320rem","centered":""},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[(_vm.isLoginType == 2)?_c('div',{staticClass:"register"},[_c('div',{staticClass:"title-wrap"},[_c('div',{staticClass:"title"},[_c('img',{staticClass:"user",attrs:{"src":require("@/assets/login/user.png")}}),_c('span',[_vm._v(_vm._s(_vm.$t("login.1")))])])]),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'validateLoginUser',
              _vm.validatorRules.validateLoginUser,
            ]),expression:"[\n              'validateLoginUser',\n              validatorRules.validateLoginUser,\n            ]"}],staticClass:"userAccount",attrs:{"placeholder":_vm.$t('login.2')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"}),_c('span',{attrs:{"slot":"prefix"},slot:"prefix"},[_vm._v("+55")])],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'validatePassword',
              _vm.validatorRules.validatePassword,
            ]),expression:"[\n              'validatePassword',\n              validatorRules.validatePassword,\n            ]"}],attrs:{"placeholder":_vm.$t('login.3'),"autocomplete":"off"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'validateComPassword',
              _vm.validatorRules.validateComPassword,
            ]),expression:"[\n              'validateComPassword',\n              validatorRules.validateComPassword,\n            ]"}],attrs:{"placeholder":_vm.$t('login.4'),"autocomplete":"off"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('div',{staticClass:"btn-wrap"},[_c('a-form-item',[_c('a-button',{staticClass:"btn",attrs:{"html-type":"submit","type":"primary"}},[_vm._v(_vm._s(_vm.$t("login.7")))])],1)],1),_c('div',{staticClass:"changeLogin",on:{"click":function($event){return _vm.changeLogin(1)}}},[_vm._v(" "+_vm._s(_vm.$t("login.5"))+" ")])],1)],1):_vm._e(),(_vm.isLoginType == 1)?_c('div',{staticClass:"login"},[_c('div',{staticClass:"title-wrap"},[_c('div',{staticClass:"title"},[_c('img',{staticClass:"user",attrs:{"src":require("@/assets/login/user.png")}}),_c('span',[_vm._v(_vm._s(_vm.$t("login.9")))])])]),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'validateLoginUser',
              _vm.validatorRules.validateLoginUser,
            ]),expression:"[\n              'validateLoginUser',\n              validatorRules.validateLoginUser,\n            ]"}],staticClass:"userAccount",attrs:{"placeholder":_vm.$t('login.2')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"}),_c('span',{attrs:{"slot":"prefix"},slot:"prefix"},[_vm._v("+55")])],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'validatePassword',
              _vm.validatorRules.validatePassword,
            ]),expression:"[\n              'validatePassword',\n              validatorRules.validatePassword,\n            ]"}],attrs:{"placeholder":_vm.$t('login.3'),"autocomplete":"off"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('div',{staticClass:"btn-wrap"},[_c('a-form-item',[_c('a-button',{staticClass:"btn",attrs:{"html-type":"submit","type":"primary"}},[_vm._v(_vm._s(_vm.$t("login.8")))])],1)],1),_c('div',{staticClass:"changeLogin",on:{"click":function($event){return _vm.changeLogin(2)}}},[_vm._v(" "+_vm._s(_vm.$t("login.6"))+" ")])],1)],1):_vm._e(),_c('img',{staticClass:"close",attrs:{"src":require("@/assets/login/close.png")},on:{"click":_vm.handleCancel}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
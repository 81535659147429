import {
  request
} from '../request'

// 获取游戏列表
export const getGameList = (param) => request(`/game/listByLabel`, 'POST', param)

// 获取进入游戏链接
export const gameLink = (param) => request(`/game/forward`, 'POST', param)

// 获取游戏历史记录
export const getGameHistory = (param) => request(`/user/getGameHistory`, 'POST', param)
<template>
  <div class="bottom-wrap">
    <div class="center">
      <div class="bottom-menu-wrap">
        <div v-for="(item, i) in menuList" :key="i" class="menu-item">
          <span class="menu-title" @click="handleLink(item)">{{ $t(item.title) }}</span>
          <div>
            <div v-for="(items, j) in item.list" :key="j">
              <img v-if="items.img" :src="items.img" />
              <span v-else class="menu-link-txt" @click="handleLink(items)">{{ $t(items.title) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-txt">
        {{ $t("about.3") }}
      </div>
      <div class="version">V{{ appVersion }}</div>
    </div>
  </div>
</template>

<script>
import { menuList } from "./config.js";
export default {
  props: {},
  name: "Bottom",
  data() {
    return {
      menuList,
    };
  },
  computed: {
    appVersion() {
      return require("../../../package.json").version;
    },
  },
  methods: {
    handleLink(item) {
      this.$store.commit("setLoginCallBack", () => {
        if (item.url) {
          this.$router.push(item.url);
        }
      });
      this.$store.dispatch("handleCheckLogin");
    },
  },
};
</script>

<style lang="less" scoped>
@import "./pc.less";
@media only screen and (max-width: @screen-width4) {
  @import "./h5.less";
}
</style>

/*
 * @Author: stingyxin 894739825@qq.com
 * @Date: 2023-05-24 15:32:00
 * @LastEditors: stingyxin 894739825@qq.com
 * @LastEditTime: 2023-05-24 16:58:20
 * @FilePath: \testgamehall\assets\script\webSDK\facebook\FbEvent.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 数数 打点统计
 * 更多接口参照《ThinkingAnalyticsAPI》类 ,外部不能直接调用,
 * 必须通过ThinkingEvent._ta 调用
 */
import Vue from "vue"

var vm = new Vue()

import { getCookieByName, getQueryString } from "@/utils/WebCall";
/**初始化sdk Facebook*/
export function init(debug) { }

/**获得fb事件 */
function getFBEvent() {
  return (window).fbq;
}

/**
 * 上报事件
 * @param eventName 事件名称
 * @param agrs 事件参数
 * @returns
 */
function track(eventName, agrs, eventId) {
  let _fbEvent = getFBEvent();
  if (!_fbEvent) {
    return;
  }
  if (agrs) {
    _fbEvent("track", eventName, agrs, { eventID: eventId });
  } else {
    _fbEvent("track", eventName, {}, { eventID: eventId });
  }
}

/**注册事件 */
export function EventRegistr(userId) {
  // console.log("register");
  track("CompleteRegistration", "", userId);
}

/**发起充值事件 */
export function EventDoInitiateCheckout(value, eventId) {
  let goldNum = value;
  let args = {
    value: goldNum,
    currency: vm.ChannelConfig.currency,
    // external_id: CoreBaseData.getInstance().getExternalId()
  };
  let isSuc = track('AddToCart', eventId, args);
  return isSuc;
}

/**充值事件 */ /**首充 */
export function EventDoCharge(value, orderId) {
  // debugger
  let debug = false
  if (value != null) {
    let gold = parseInt(parseInt(value) / 100);
    if (debug) {
      track(text, { value: gold, currency:  vm.ChannelConfig.currency }, orderId);
    } else {
      track("Purchase", { value: gold, currency: vm.ChannelConfig.currency }, orderId);
    }

  } else {

  }
}
/**
 * 每日登录
 */
export function EventdailyLogin() {
  track("login");
}
/**
 * 付费（包含首充的所有付费）
 */
export function EventPay(value, item) {
  let debug = false;
  if (value != null) {
    let gold = parseInt(value);
    if (debug) {
      //测试
      track("purchase", { value: gold, currency: item });
    } else {
      //正式
      track("purchase", { value: gold, currency: 'BRL' });
    }
  } else {
    return;
  }
}
/**
 * 开始游戏
 */
export function EventStartgame() {
  console.log("gamestart");
  track("gamestart");
}
/**获取fbc 结果参考: fb.1.1554763741205.AbCdEfGhIjKlMnOpQrStUvWxYz1234567890 1*/
export function getFbc() {
  // if (!cc.sys.isBrowser) {
  //   return null;
  // }

  /**优先取cookie中的值 有就用 没有就通过链接去拼 */
  let fbc = getCookieByName('_fbc');
  if (fbc) {
    return fbc;
  }

  let _fbp = getFbp();
  if (_fbp) {
    let arr = _fbp.split('.');
    let subdomainIndex = arr[1];
    let createTime = arr[2];
    let fbclid = getQueryString('fbclid');
    if (fbclid) {
      return `fb.${subdomainIndex}.${createTime}.${fbclid}`;
    }
  }
  return '';
}

// 新增
/**获取fbp 结果参考: fb.1.1596403881668.1116446470 1*/
export function getFbp() {
  let fbp = getCookieByName('_fbp');
  return fbp;
}

/**获取客户端使用地址 1*/
export function getEventUrl() {
  return window.location.href;
}

/**获取客户端用户代理程序 1*/
export function getUserAgent() {
  return navigator.userAgent;
}

export default {
  EventRegistr,
  EventDoCharge,
  EventdailyLogin,
  EventPay,
  EventStartgame,
  getFbc,
  getFbp,
  getEventUrl,
  getUserAgent,
  EventDoInitiateCheckout
}

import Vue from 'vue'
import Vuex from 'vuex'
import {
  userMoney,
} from "@/services/api/user";
import router from '@/router'
import ConstConfig from "@/config/ConstConfig";
import { getGold } from "@/handle/RefreshBalance";
import { autoRegistration } from "@/handle/LoginHandle";
// import route from '@/route'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    lang: 'BR',
    loginShow: false,  // 登录弹窗显示
    loginType: 1,  // 登录弹窗类型
    loginInfo: {
      // user: 'hhhxaaa'
    }, // 登录用户信息
    loginCallBack: null,  // 登录回调
    payModalVisible: false,  // 充值弹窗
    token: "",  //玩家token
    isLogin: 0, //玩家是否登录 1:是 0:否
    currency: "BRL",//货币单位
    gameUrl: "",//全局内嵌游戏地址 localStorage.getItem(ConstConfig.LOCAL_CONST.GAME_URL)
    localeLanguage: "", //当前语言环境
    gold: 0, //账户余额
    code: "", //邀请码
    nickName: "", //玩家昵称
    headUrl: "", //玩家头像
    userId: "", //用户id
    userType:"", //获取哪一个客服链接
    VIP:"", //VIP等级
    WithdrawPopups: 0,//111提现成功弹窗
    payModalAmonut: 0,//提现--固定充值金额激活
    myLevel: 0,//VIP等级
    joinGameTipsType: 0,//点击三方游戏提示类型
    spinning: false, //全局加载效果
    visible: 0, //0：关闭 1：VIP>=2&&超过3000提示可见 2:VIP0 && (游戏余额-平台余额)/100>1 3:新用户为登录提示 4新用户登录提示
    Time: null, //定时器 用户点击浏览器返回清空
    gameType: "", //玩家点击哪个类型的游戏
  },
  mutations: {
    // 控制登录弹窗显示
    handleLoginVisible(state, value) {
      state.loginShow = value
    },
    // 设置用户信息
    setUserInfo(state, value) {
      state.loginInfo = value
    },
    // 设置登录回调
    setLoginCallBack(state, value) {
      state.loginCallBack = value
    },
    // 设置充值弹窗显示
    setPayModalVisible(state, value) {
      state.payModalVisible = value
      if(value == true){
        let nowRoute = router.currentRoute.path;
        let query = router.currentRoute.query;
        router.push({
          path: nowRoute,
          query: {
            "model": "Paymodel",
            ...query
          },
        });
      }
    },
    // 刷新账户余额
    getRefreshBalance(state, value) {
      if(state.isLogin == 1){
          getGold();
      }
    }
  },
  actions: {
    // 验证登录
    handleCheckLogin({ commit, state }) {
      /**未获取到获取设备id进行一键登录*/ 
      let deviceId = localStorage.getItem(ConstConfig.LOCAL_CONST.DEVICE_ID);
      if(!deviceId){
        autoRegistration()
      }
      // 未获取到token值视为未登录状态
      else if (!state.token) {
        commit('handleLoginVisible', true)
      } else {
        state.loginCallBack && state.loginCallBack()
      }
    }
  }
})

export default store

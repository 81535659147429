<template>
  <div class="header-wrap">
    <div class="header-left">
      <img v-if="isLogin == 0" class="logo" src="@/assets/logo/logo.png" alt="logo" />
      <div class="user-id" v-else>
        <div class="avatar">
          <img src="@/assets/img_txn12.png" />
          <VipTag class="vip" grade="0" />
        </div>
        <div class="user">
          <div class="userName">{{ $store.state.nickName }}</div>
          <div class="ID">ID:{{ $store.state.userId }}</div>
        </div>
      </div>
    </div>
    <div class="header-right">
      <div class="user-info" v-if="isLogin == 1">
        <!-- 账户余额 -->
        <div class="bottom">
          <div class="country-img">
            <img src="@/assets/logo/amount.png" />
          </div>
          <div class="count">
            {{ $utils.changeMoney($store.state.gold) }}
          </div>
          <div class="sync-icon" @click="handleSync">
            <img :class="{ 'refresh-loading': spin }" src="@/assets/logo/refresh.png" />
          </div>
        </div>
        <!-- 充值/提现按钮 -->
        <div class="button left">
          <img src="@/assets/logo/charge.png" class="purse" />
          <div class="charge" @click="goCharge">
            {{ $t("Common.deposit") }}
          </div>
        </div>
      </div>
      <div class="user-info" v-else>
        <span class="link login" @click="login(1)">
          <img src="@/assets/login/user-login.png" class="user">
          <img src="@/assets/login/Login.png" class="img">
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import VipTag from "@/components/VipTag";
export default {
  name: "topHeader",
  data() {
    return {
      spin: false,
    };
  },
  components: {
    VipTag,
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
  },
  watch: {},
  created() {
    this.handleSync();
  },
  activated() {},
  methods: {
    goCharge() {
      this.$store.commit("setLoginCallBack", () => {
        this.$store.commit("setPayModalVisible", true);
      });
      this.$store.dispatch("handleCheckLogin");
    },
    handleSync() {
      this.$store.commit("getRefreshBalance", true);
      this.spin = true;
      setTimeout(() => {
        this.spin = false;
      }, 1000);
    },
    login(type) {
      this.$store.state.loginType = type;
      this.$store.dispatch("handleCheckLogin");
    },
  },
};
</script>

<style lang="less" scoped>
@import "./pc.less";
@media only screen and (max-width: @screen-width4) {
  @import "./h5.less";
}
</style>

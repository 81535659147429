module.exports = {
  // 公共
  'Common.payouts': 'Saques',
  'Common.deposit': 'Depósito',
  'Common.all': 'Tudos',
  'Common.CopySuccess': 'Copiado com êxito',
  // Header
  'Header.login': 'Login',
  'Header.registro': 'Registro',
  // BottomNav
  'BottomNav.home': 'Inicio',
  'BottomNav.share': 'Instalar',
  'BottomNav.promotion': 'Promoção',
  'BottomNav.inTheTank': 'Suporte',
  'BottomNav.user-info': 'Perfil',
  // Pay
  'Pay.deposit-online': 'Depósito on-line',
  'Pay.deposit-money': 'Valor do Depósito',
  'Pay.deposit-prompt': 'Termos de uso：Após o pagamento bem-sucedido, o sistema adicionará o valor para sua conta de 1 a 5 minutos.',
  'Pay.min': 'Mínimo',
  'Pay.max': 'Máximo',
  'Pay.deposit-submit': 'Recarregue Agora',
  'Pay.Extra': 'Extra',
  // History
  'History.1': 'CONTA',
  'History.2': 'TAXA DE PROGRESSO',
  'History.3': 'DINHEIRO',
  // Withdraw
  'Withdraw.1': 'Valor disponível para saques',
  'Withdraw.2': 'Conta PIX',
  'Withdraw.3': 'Quantidade retirada',
  'Withdraw.4': 'Extrair',
  'Withdraw.5': 'O saldo de retirada é insuficiente',
  'Withdraw.6': 'confirme',
  'Withdraw.7': 'Dica',
  'Withdraw.8': 'Selecione o CPF, número de telefone e endereço de e-mail corretamente, e o número da conta deve estar correto para sacar fundos.',
  'Withdraw.9': 'A retirada falhou, verifique se as informações da conta de retirada estão corretas ou altere a conta de retirada e reinicie a retirada.',
  // WithdrawPopups
  'WithdrawPopups.1': 'DICA',
  'WithdrawPopups.2': 'Caro usuário, você é atualmente um usuário do nível Black Iron e não desbloqueou a permissão de saques. Recarregue qualquer quantia para desbloquear apermissao de saques e depois retire o dinheiro.',
  'WithdrawPopups.3': 'Vá para recarregar',
  'WithdrawPopups.4': 'Caro usuário VIP, seu pedido de saques foi aceito, preste atenção ao saldo de sua conta bancária',
  'WithdrawPopups.5': 'confirme',
  'WithdrawPopups.6': 'Prezado membro, esta é a primeira vez que você saca dinheiro. Para que você se familiarize com o processo de saques desta plataforma, escolha o menor valor de saques de {amount} para saques.',
  'WithdrawPopups.7': 'Vá para tarefa',
  'WithdrawPopups.8': 'Prezado usuário, você está convidado a participar do plano de melhoria do jogo. Por favor, complete a experiência do jogo antes de fazer saques.',
  'WithdrawPopups.9': 'Vá para as configurações',
  'WithdrawPopups.10': 'Caro usuário：<br/>&nbsp;&nbsp;Para a segurança da sua conta, defina uma senha de saques e vincule uma conta de saques exclusiva a tempo após o primeiro saques bem-sucedido.<br/>&nbsp;&nbsp;Após a vinculação, a conta do jogo só suporta saques da conta bancária vinculada, protegendo efetivamente a segurança dos fundos da sua conta.',
  'WithdrawPopups.11': 'Caro usuário, o limite único de saques para usuários do nível Bronze é {amount}, selecione {amount} para sacar. Atualize para Prata para desbloquear mais valores de retirada pessoais.',
  'WithdrawPopups.12-1': 'Prezado usuário do nível Bronze, as regras de saques para o seu nível VIP atual são:<br/>1. Você pode sacar dinheiro uma vez a cada 60 horas<br/>2. O limite para cada saques é {amount}. Aguarde pacientemente ',
  'WithdrawPopups.12-2': ' horas antes de sacar dinheiro，ou você pode atualizar para o nível Prata.<br/><br/>As regras de retirada do nível Prata são:<br/>1. Você pode sacar dinheiro 5 vezes ao dia<br/>2. Cada limite de saques é de R$300<br/>',
  'WithdrawPopups.13': 'Atualizar para Prata',
  'WithdrawPopups.14': 'Deposite {amount} para fazer upgrade para Prata',
  'WithdrawPopups.15': 'Parabéns por alcançar nosso nível de usuário Rei incomparável mais alto! Você só precisa recarregar uma vez, com o valor chegando a {amount} ou superior, para desbloquear todos os benefícios de Rei incomparável. Uma vez desbloqueado, você desfrutará dos seguintes privilégios:<br/>1. Remova todas as restrições do jogo<br/>2. Retire dinheiro facilmente a qualquer momento, sem limite máximo de valor e número de saques diários<br/>3. Utilize o canal de saques exclusivo do Rei incomparável e os saques chegarão imediatamente.<br/>',
  'WithdrawPopups.16': 'Desbloqueie e pague {amount}',
  'WithdrawPopups.17': 'Você tem atualmente um pedido de saques em processamento. Aguarde até que o pedido de saques seja processado com sucesso antes de prosseguir com a operação de saques. Obrigado.',
  'WithdrawPopups.18': 'Contas ligadas',
  'WithdrawPopups.19': 'Usuário respeitado:<br/>Conclua a associação da conta de retirada designada e você poderá sacar dinheiro normalmente após sucesso.<br/>Especifique o método de associação para a conta de retirada:<br/>1. Insira a conta de saques que você precisa especificar<br/>2. Deposite {amount} para ativar a conta de saques designada',
  'WithdrawPopups.20': 'Deposite {amount} <br/>para ativar conta',
  'pix.1_1': 'Passo 1',
  'pix.1_2': 'Passo 2',
  'pix.1_3': 'Passo 3',
  'pix.1_4': 'Passo 4',
  'pix.2': 'Definir senha de saques',
  'pix.3': 'Por favor defina sua senha de saques',
  'pix.4': 'Por favor insira a senha de 6 dígitos',
  'pix.5': 'Por favor, lembre-se da sua senha de retirada após configurá-la',
  'pix.6': 'Próximo passo',
  'pix.7': 'Vincule uma conta de saques exclusiva',
  'pix.8': 'Nome do titular da conta',
  'pix.9': 'Por favor insira o nome do titular da conta',
  'pix.10': 'Por favor insira sua conta PIX',
  'pix.11': 'Passos acima',
  'pix.12': 'Após vincular uma conta de saques exclusiva, o sistema só suporta saques de jogos da conta bancária associada, tornando os fundos de sua conta de jogo mais seguros.',
  'pix.13': 'Confirmar informações',
  'pix.14': 'Senha para saques:',
  'pix.15': 'Dono da conta:',
  'pix.16': 'Por favor, verifique as informações da sua conta cuidadosamente e lembre-se da sua senha de saques para garantir a segurança dos fundos da sua conta.',
  'pix.17': 'Verificar conta&Senha de ativação',
  'pix.18': 'Por favor, ative a senha de saques e verifique a conta de saques exclusiva',
  'pix.19': 'Método:',
  'pix.20': '1. Deposite <span style="color: #FEC929;font-size:14rem;">{amount}</span> através do botão "Ativação" abaixo <br>2. Após finalizar o pagamento, o sistema ativa automaticamente a senha de saques e verifica a conta de saques.',
  'pix.21': 'Após a finalização do pagamento, a conta de saques será vinculada automaticamente e você poderá usufruir do saques normalmente.',
  'pix.22': 'Ativação',
  'pix.23': 'Detalhes do pedido',
  'pix.24': 'Valor do pedido:',
  'pix.25': 'Montante recebido:',
  'pix.26': 'Vá e pague {amount}',
  // drawcash
  'drawcash.1': 'Adicionar chave pix de saques',
  'drawcash.name1': 'Nome do titular da conta',
  'drawcash.name2': 'Por favor insira o nome do titular da conta',
  'drawcash.type': 'Tipo de chave PIX',
  'drawcash.PIX': 'PIX',
  'drawcash.PIX1': 'Por favor insira sua conta PIX',
  'drawcash.CPF': 'CPF',
  'drawcash.CPF1': 'Por favor insira seu CPF',
  'drawcash.tellNum': 'Número de telefone',
  'drawcash.tellNum1': 'Por favor, digite seu número de telefone',
  'drawcash.confirm': 'confirme',
  // PayModal
  'PayModal.deposit-log': 'Registro de recarga',
  'PayModal.deposit-verify-money': 'Por favor, escolha o valor que desejarecarregar',
  // LeftNav
  'LeftNav.popular': 'Popular',
  'LeftNav.recently': 'Recente',
  'LeftNav.favorites': 'Favoritos',
  // More
  'More.game-more': 'A exibir {current} jogos entre {total} {name} jogos',
  'More.more': 'Carregar mais',
  //Mine
  'Mine.recover': 'Recuperar o saldo',
  'Mine.millions-month': 'milhões mensais',
  'Mine.account': 'Conta',
  'Mine.user-info': 'Perfil',
  'Mine.VIP': 'Nível VIP',
  'Mine.service': 'Serviço ao cliente',
  'Mine.language': 'linguagem',
  'Mine.loginOut': 'Deslogar',
  'Mine.bind': 'Vincular conta',
  'Mine.loginSuccess': 'Sair com sucesso',
  'Mine.1': 'Vincular conta de login',
  'Mine.2': 'Configurar conta de login',
  'Mine.3': 'Confirme',
  // Promote
  'Promote.invite': 'Convidar',
  // Safety
  'Safety.security-center': 'Centro de Segurança',
  'Security.Withdraw': 'Saques',
  'Security.record': 'Registro de Retiradas',
  // Notice
  'Notice.bonus': 'Bônus de Sugestão',
  'Notice.message-center': 'Centro de mensagens',
  'Notice.message': 'Notícia',
  'Notice.notice': 'Notificação',
  'Notice.scroll-panel': 'Painel Rolante',
  // Setting
  'Setting.data': 'Dados',
  'Setting.back': 'Retornar',
  'Setting.save': 'Salvar',
  'Setting.day': 'Dia',
  'Setting.month': 'Mês',
  'Setting.year': 'Ano',
  // promote
  'Promote.success': 'Recebido com sucesso',
  'Promote.failed': 'Falha ao coletar',
  'Promote.copy': 'cópia',
  'Promote.invite': 'convite',
  'Promote.my': 'meu convite',
  'Promote.Today': 'Hoje',
  'Promote.All': 'Todos',
  'Promote.Number': 'Registro',
  'Promote.Rewards': 'prêmio',
  'Promote.myRewards': 'Minha recompensa',
  'Promote.received': 'Recebida',
  'Promote.receive': 'receber',
  'Promote.collected': 'Disponível',
  'Promote.media': 'Compartilhe com sua mídia social',
  'Promote.friends': 'Compartilhe o link com seus amigos',
  // vip
  'Vip.title': 'VIP CLUB',
  'Vip.1': 'Nível desbloqueado',
  'Vip.2': 'Atualize para <span style="color: red;">{VIP}</span> para desbloquear mais privilégios',
  'Vip.3': 'Nível VIP a ser destravado',
  'Vip.4': 'Destravado',
  'Vip.5': 'Parabéns, você desbloqueou todos os níveis.',
  'vip.0': 'Ferro preto',
  'vip.1': 'Bronze',
  'vip.2': 'Prata',
  'vip.3': 'Ouro',
  'vip.4': 'Diamante',
  'vip.5': 'Grão-Mestre',
  'vip.6': 'Rei',
  'vip.7': 'Rei incomparável',
  // service
  'Service.service': 'Atendimento ao Cliente',
  'Services.common': 'problema comum',
  'Services.How': 'Como posso sacar dinheiro?',
  'Services.withdrawal': 'Caros usuários, as retiradas são privilégios VIP. Recarregue qualquer quantia para atualizar para VIP e desbloquear permissões de retirada.',
  'Services.1': 'Existe alguma taxa de manuseio para saques?',
  'Services.2': 'Não cobramos nenhuma taxa de retirada. Acreditamos em fornecer aos jogadores uma experiência de jogo transparente e justa, e isso inclui permitir que você ganhe bônus sem nenhum custo extra para você',
  // eventItem1/3
  'EventItem.Receber': 'RECEBER',
  'EventItem.rewards': 'Conclua tarefas para obter recompensas',
  'EventItem.Already': 'Já recebeu',
  'EventItem.1': 'completado',
  'EventItem.2': 'Lucky Slots de recomendação de apostas:',
  'EventItem.3': 'compartilhar',
  // eventItem2
  'eventItem2.checkin':'CHECK IN',
  'eventItem2.Complete':'Complete o jogo uma vez para receber a recompensa do dia.',
  'eventItem2.Successful':'Check-in bem sucedido',
  'eventItem2.failed':'Check-in falhou',
  'eventItem2.1':'Tarefa iniciante',
  'eventItem2.2':'Bônus diário',
  'eventItem2.3':'Tarefas do sistema',
  // about
  'About.about': 'Sobre',
  'about.1': 'Lucky Slots é um jogo de caça-níqueis gratuito! Temos uma grande variedade de jogos de caça-níqueis no estilo de Las Vegas, oferecendo a mais ampla variedade de jogos e os produtos mais competitivos do mercado. Todas as plataformas são acessadas e selecionadas somente entre as plataformas de jogos on-line reconhecidas internacionalmente para garantir que todos os jogadores possam jogar em um ambiente justo e confiável. Além disso, o governo brasileiro monitora os dados da plataforma de jogos para garantir a imparcialidade e a autenticidade dos jogos. Por favor, aproveite a diversão de jogar no Lucky Slots!',
  'about.1.bak': 'Lucky Slots é um jogo de caça-níqueis grátis! Temos uma extensa coleção de slots estilo Vegas e oferecemos a mais ampla variedade de jogos e produtos mais competitivos do mercado. Todas as plataformas acessam e selecionam apenas plataformas de jogos online de renome internacional para garantir que cada jogador possa jogar em um ambiente justo e confiável. Ao mesmo tempo, o governo da Costa Rica também monitorará os dados da plataforma de jogos para garantir a justiça e a autenticidade do jogo.Aproveite os jogos no Lucky Slots!',
  'about.2': 'Sobre Nós',
  'about.3': 'Lucky Slots é uma das famosas empresas internacionais de operação de cassinos online, oferecendo uma variedade de jogos emocionantes, caça-níqueis, pesca, loterias, esportes e muito mais. Lucky Slots garante que cada jogador possa jogar em um ambiente justo e confiável e está empenhado em fornecer aos jogadores a melhor experiência de jogo.',
  //notice
  'notice.1': 'Parabéns ',
  'notice.2': ' Saques',
  // empty
  'empty.1': 'Nenhum jogo disponível',
  // limitPops
  'limitPops.1': 'DICA',
  'limitPops.3': 'O sistema está em manutenção, fique atento',
  'limitPops.4': 'Confirme',
  'limitPops.5': 'Você é atualmente um usuário do nível Ferro preto e só pode experimentar jogos Fortune OX. Os jogos PG estão disponíveis apenas para usuários do nível Bronze.',
  'limitPops.6': 'Jogue Fortune OX',
  'limitPops.7': 'Vá para desbloquear',
  'limitPops.8': 'Parabéns por receber os R$10 em dinheiro presenteados pelo sistema,e você pode começar a jogar para ganhar mais dinheiro imediatamente.',
  'limitPops.9': 'Atualmente você é um usuário do nível Ferro preto e só pode jogar jogos Fortune OX.Os jogos {gameType} estão disponíveis apenas para usuários do nível {vip}.',
  'limitPops.10': 'Recarregue {amount} para atualizar para o nível Rei Incomparável e remover todas as restrições do jogo.',
  'limitPops.11': 'Vá para recarregar',
  'limitPops.12': 'O jogo JILI está aberto apenas para usuários do nível Silver e pode ser desbloqueado atualizando para Silver.',
  'limitPops.13': 'O jogo PP está aberto apenas para usuários do nível Ouro e pode ser desbloqueado atualizando para Ouro.',
  'limitPops.14': 'O jogo MG está aberto apenas para usuários do nível Diamante e pode ser desbloqueado atualizando para Diamante.',
  'limitPops.15': 'O jogo {gameType} está aberto apenas para usuários do nível Grão-Mestre e pode ser desbloqueado atualizando para Grão-Mestre.',
  'limitPops.16': 'O jogo {gameType} está aberto apenas para usuários do nível Rei e pode ser desbloqueado atualizando para Rei.',
  'limitPops.20': 'O jogo {gameType} está aberto apenas para usuários do nível Rei incomparável e pode ser desbloqueado atualizando para Rei incomparável.',
  'limitPops.17': 'Jogue jogos PG',
  'limitPops.18': 'Desbloquear outros jogos',
  'limitPops.19': 'Recarregue qualquer quantia para atualizar para Bronze e desbloquear todos os jogos PG',
  'limitPops.21': '',
  // bottom
  'bottom.1': 'Cassino',
  'bottom.1_1': 'Eventos',
  'bottom.1_2': 'Bónus',
  'bottom.1_3': 'VIP',
  'bottom.1_4': 'Convidar',
  'bottom.2': 'Jogo',
  'bottom.2_1': 'Slots',
  'bottom.2_2': 'Pescaria',
  'bottom.3': 'Suporte',
  'bottom.3_1': 'Suporte online',
  'bottom.4': 'introduzir',
  'bottom.5': 'Outros',
  // tipsPop
  'tipsPop.1': 'Caros jogadores, parabéns! Como você ganhou mais de R$ 3.000 no total, você foi atualizado para nosso nível mais alto de status de usuário Rei incomparável. Feliz jogo!',
  'tipsPop.2': 'Parabéns',
  'tipsPop.3': 'Parabéns, o saldo da sua conta ultrapassou {amount}, vá sacar dinheiro!',
  'tipsPop.4': 'Retirar dinheiro',
  // commonPops新用户登录提示
  'tipsPop.5': 'Prezado usuário, seja bem vindo ao mundo cassino do Lucky Slots, cadastre-se agora e ganhe um bônus de R$7!',
  'tipsPop.6': 'Registrar agora',
  // login
  'login.1': 'Registre sua conta',
  'login.9': 'Login',
  'login.2': 'Digite o número do celular',
  // 'login.2': 'Digite o número do celular/endereço de e-mail',
  'login.3': 'Por favor, insira sua senha',
  'login.4': 'Por favor digite sua senha novamente',
  'login.5': 'Já tem uma conta? Conta de login',
  'login.6': 'Não tem uma conta ainda? Registar Conta',
  'login.7': 'Registro',
  'login.8': 'Login',
  'login.10': 'Ou',
}


export function getCookieByName(name) {
    // console.log("document.cookie",document.cookie);

    var r = document.cookie.match("\\b" + name + "=([^;]*)\\b");
    return r ? r[1] : "";
}

export function getQueryString(name) {
    let href = window.location.href;
    let search = href.substr(href.indexOf("?"));
    if (!search) {
        search = window.location.search;
    }
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let rs = search.substr(1);
    let r = rs.match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return "";
}
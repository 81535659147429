import store from '@/store';
import ConstConfig from "@/config/ConstConfig";
import {
  userLevel,
  getFbRechargeRecords,
  updateFbRechargeRecords,
} from "@/services/api/user";
import { changeAmount } from "@/utils/CurrencyUtils";
import FbEvent from "@/utils/FbEvent";
import KwaiqEvent from "@/utils/KwaiqEvent";

/** 获取账户最新余额 */
export function getGold() {
    if(!store.state.token){
      return
    }
    let myLevel = 0;
    userLevel({}).then((res) => {
      if (res.success) {
        let info = res.data.result;
        store.state.gold = info.accountGold;

        if (info.isUnlockVipEight == 0) {
          myLevel = info.vipLevel;
        } else if (info.isUnlockVipEight == 1) {
          info.isRealVipEight == 0 && ((myLevel = 6));
          info.isRealVipEight == 1 && ((myLevel = 7));
        }
        store.state.myLevel = myLevel;
        localStorage.setItem(ConstConfig.LOCAL_CONST.MY_LEVEL, myLevel);
      }
    });
    getReports();
}

/**获取记录上报事件 并通知接口已上报*/
export function getReports() {
    getFbRechargeRecords({}).then(async (res) => {
      if (res.success) {
        if (res.data.fbRechargeRecords && res.data.fbRechargeRecords.length > 0) {
          let result = res.data.fbRechargeRecords;
          let orderIds = [];
          await setTimeout(() => {
            for (let i in result) {
              let money = changeAmount(result[i].amount);
              let orderId = result[i].orderId;
              if (result[i].reportStatus == 0) {
                store.state.isfirstChargeStatus = false;
                //0未上报 1已上报
                if (result[i].isFirstRecharge == 1) {
                  //1首充 0非首充
                  FbEvent.EventDoInitiateCheckout(money, orderId);
                  FbEvent.EventDoCharge(money, orderId);
                  let createDay = localStorage.getItem(ConstConfig.LOCAL_CONST.CREATE_DAY);
                  if (createDay && parseInt(createDay) != new Date().getDate()) {
                    return;
                  }
                  KwaiqEvent.EventFirstChargeKwaiq(money);
                  KwaiqEvent.EventDoCharge(money);
                  orderIds.push(orderId);
                } else {
                  if (store.state.userType == 6) {
                    localStorage.setItem(ConstConfig.LOCAL_CONST.WITHDRAW_COUNT_F, "3");
                  }
                  FbEvent.EventDoCharge(money, orderId);
                  KwaiqEvent.EventDoChargeKwaiq(money);
                  orderIds.push(orderId);
                }
              }
            }
            orderIds.length > 0 &&
              updateFbRechargeRecords({
                reportStatus: 1,
                orderIds,
              }).then((res) => {});
          }, 1000);
        }
      }
    });
}

/**
 * 单独用于进入游戏出来做处理 
 * 当VIP==0 账户余额超过100 
 * 当VIP等级==2 && 金额产生了变化 && 金额>3000
 **/ 
export function exitIframe() {
  let gameUrl = localStorage.getItem(ConstConfig.LOCAL_CONST.GAME_URL);
  if(gameUrl){
    localStorage.setItem(ConstConfig.LOCAL_CONST.GAME_URL, "");
    let vip = localStorage.getItem(ConstConfig.LOCAL_CONST.MY_LEVEL);
    let gold_before = Number(localStorage.getItem(ConstConfig.LOCAL_CONST.GOLd_BEFORE));
    if(vip && vip != ''){
      store.state.myLevel = parseInt(vip);
    }
    vip = store.state.myLevel;
    // VIP等级为0时记录对比余额最高值
    let GOLdEST = 0;
    if (vip == 0) {
      GOLdEST =
        parseInt(localStorage.getItem(ConstConfig.LOCAL_CONST.GOLdEST)) || 0;
      if (gold_before > GOLdEST) {
        localStorage.setItem(
          ConstConfig.LOCAL_CONST.GOLdEST,
          gold_before
        );
      }
      GOLdEST = parseInt(
        localStorage.getItem(ConstConfig.LOCAL_CONST.GOLdEST)
      );
    }
    if (vip != 1) {
      // store.state.Time = setInterval(() => {
        userLevel({}).then((res) => {
          if (res.success) {
            let accountGold = res.data.result.accountGold;
            if (
              vip >= 2 &&
              accountGold >= 300000 &&
              gold_before != accountGold
            ) {
              let TIPS = localStorage.getItem(
                ConstConfig.LOCAL_CONST.TIPS_3000
              );
              if (!TIPS) {
                store.state.visible = 1;
                localStorage.setItem(ConstConfig.LOCAL_CONST.TIPS_3000, "1");
              }
              // clearInterval(store.state.Time);
            } else if (
              vip == 0 &&
              GOLdEST < accountGold &&
              gold_before < accountGold &&
              accountGold >= 10000
            ) {
              if (gold_before < 10000) {
                store.state.visible = 2;
                // clearInterval(store.state.Time);
              } else {
                let num = Math.floor(gold_before / 10000) * 10000;
                let showTip = (accountGold - num) / 10000;
                if (showTip > 1) {
                  store.state.visible = 2;
                  // clearInterval(store.state.Time);
                }
              }
            }
          }
        });
      // }, 3000);
    }
  }
}

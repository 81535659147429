<template>
  <div>
    <a-drawer
      placement="bottom"
      :closable="false"
      :visible="true"
      @close="onCancel"
      class="pay-modal"
      height="90%"
    >
      <div class="modal-wrap">
        <div class="header-wrap">
          <a-icon type="left" @click="handleBack" />
          <span>{{ config[type].title }}</span>
          <span class="link" v-if="type === 'pay'" @click="type = 'history'">{{
            $t("PayModal.deposit-log")
          }}</span>
        </div>
      </div>
      <component :is="config[type].component" @onCancel="onCancel"></component>
      <!-- <pay v-show="type == 'pay'" @onCancel="onCancel"></pay>
    <History v-show="type == 'history'" @onCancel="onCancel"></History> -->
    </a-drawer>
  </div>
</template>

<script>
import Pay from "./components/Pay";
import History from "./components/History";
export default {
  props: {},
  name: "PayModal",
  components: {
    Pay,
    History,
  },
  data() {
    return {
      type: "pay", // pay  history
      config: {
        pay: {
          title: this.$t("Common.deposit"),
          component: "Pay",
        },
        history: {
          title: this.$t("PayModal.deposit-log"),
          component: "History",
        },
      },
      payActive: 0,
      payList: [
        {
          name: "PIX",
          icon: require("@/assets/icon_cz_zdy.png"),
        },
      ],
      moneyActive: "",
      moneyList: [
        {
          count: "10",
        },
        {
          count: "20",
        },
        {
          count: "30",
        },
        {
          count: "40",
        },
        {
          count: "50",
        },
        {
          count: "100",
        },
      ],
    };
  },
  watch: {
    visible(val) {
      console.log(val);
    },
  },
  methods: {
    handleBack() {
      if (this.type === "pay") {
        this.onCancel();
      } else {
        this.type = "pay";
      }
    },
    handleSubmit() {
      if (this.moneyActive === "") {
        this.$toast(this.$t("PayModal.deposit-verify-money"));
        return;
      }
      if (+this.moneyActive < 10 || +this.moneyActive > 20000) {
        this.$toast("Caro jogador, esse montante suportaCCapenas 10~20000");
        return;
      }
      this.onCancel();
    },
    onCancel(val) {
      this.$store.commit("setPayModalVisible", false);
      if (val == "onCharge") {
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./pc.less";
@media only screen and (max-width: @screen-width4) {
  @import "./h5.less";
}
</style>

<template>
  <a-config-provider :locale="en_US">
    <div id="app">
      <div class="loading" v-if="$store.state.spinning">
        <a-spin size="large"> </a-spin>
      </div>
      <a-back-top :visibilityHeight="100" :target="topTarget">
        <div class="ant-back-top-inner">
          <div class="top-icon"><a-icon type="to-top" /></div>
          <div>Topo</div>
        </div>
      </a-back-top>
      <Header />
      <div class="main-content">
        <div class="main-body" id="content">
          <div class="center-content">
            <keep-alive>
              <router-view />
            </keep-alive>
          </div>
          <Bottom />
        </div>
      </div>
      <LoginCheck />
      <PayModal v-if="$store.state.payModalVisible" />
      <!-- 加载游戏 -->
      <Iframe
        v-if="$store.state.gameUrl"
        :href="$store.state.gameUrl"
        :newStyle="'display: block !important;'"
      ></Iframe>
      <!-- 点击游戏提示/新用户注册登录提示-->
      <limitPops
        v-if="$store.state.joinGameTipsType != 0"
        :visible.sync="$store.state.joinGameTipsType != 0"
        :TipsType="$store.state.joinGameTipsType"
        @handleOk="handlelimit"
      />
    </div>
  </a-config-provider>
</template>

<script>
import Header from "@/components/Header";
import Bottom from "@/components/Bottom";
import LoginCheck from "@/components/LoginCheck";
import PayModal from "@/components/PayModal";
import en_US from "ant-design-vue/lib/locale-provider/en_US";
import moment from "moment";
import Iframe from "@/components/Iframe";
import limitPops from "@/view/home/components/limitPops";
import "moment/locale/en-au";
moment.locale("en-au");

export default {
  name: "App",
  components: {
    Header,
    Bottom,
    LoginCheck,
    PayModal,
    Iframe,
    limitPops,
  },
  data() {
    return {
      en_US,
      gameUrl: "https://www.baidu.com/",
    };
  },
  watch: {
    $route(val) {
      const H5AddClassName = "App-H5";
      const appNode = document.getElementById("app");
      if (val.meta.h5Layout) {
        appNode.classList.add(H5AddClassName);
      } else {
        appNode.classList.remove(H5AddClassName);
      }
    },
  },
  methods: {
    topTarget() {
      return document.getElementById("content");
    },
    /**点击三方游戏弹窗操作回调*/
    handlelimit(val) {
      this.$store.state.joinGameTipsType = 0;
      switch (val) {
        /**充值*/
        case "onCharge":
          this.$store.commit("setPayModalVisible", true);
          break;
        /**已解锁游戏*/
        case "PG":
          this.handleScroll("PG");
          break;
      }
    },
    handleScroll(id) {
      this.$nextTick(() => {
        const anchorElement = document.getElementById(id);
        if (anchorElement) {
          anchorElement.scrollIntoView(true);
          //{ behavior: "smooth" }  // 过渡动画
        }
      });
    },
  },
};
</script>

<style lang="less">
html {
  font-size: 1.1px;
  //font-size: 0.055vw;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica,
    Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB,
    Microsoft Yahei, sans-serif !important;
}
html,
body {
  height: 100%;
  -webkit-text-size-adjust: 100% !important;
  background: #292d35 !important;
}
@media only screen and (min-width: 540px) {
  html {
    font-size: 2px;
  }
}
@media only screen and (max-width: 300px) {
  html {
    font-size: 0.6px;
  }
}
* {
  padding: 0;
  margin: 0;
}
#app {
  display: flex;
  flex-flow: column;
  height: 100%;
  font-size: 14rem;
  color: @theme-text-color;
  max-width: 800px;
  margin: auto;
}
.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  .main-body {
    flex: 1;
    overflow: auto;
    background: @default-main-bg-color;
    .center-content {
      width: @content-center-width;
      margin: auto;
    }
  }
}
.hide {
  display: none;
}
.link {
  color: @link-color;
  cursor: pointer;
}
.ant-back-top {
  bottom: (@bottom-nav-height+20rem) !important;
}
.ant-back-top-inner {
  width: 50rem;
  height: 50rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: @default-box-shadow;
  border-radius: 8rem;
  font-size: 11rem;
  flex-flow: column;
  .top-icon {
    width: 16rem;
    height: 16rem;
    background: @link-color;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 10rem;
  }
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: unset !important;
}
.ant-menu-item-selected {
  color: unset !important;
}
.ant-menu-vertical .ant-menu-item {
  margin-top: 0 !important;
}
.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: 0 !important;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  height: auto !important;
}
.van-password-input {
  margin: 0 !important;
}
.van-tab {
  padding: 0 10rem !important;
}
.align-left-tabs .van-tabs__wrap--scrollable .van-tab--complete {
  flex: unset;
}
.van-tabs__line {
  background-color: #1890ff !important;
}
.van-cell__title {
  flex: unset !important;
}
// [class*="van-hairline"]::after {
//   border: none !important;
//   border-bottom: 1rem solid #ddd !important;
// }
.ant-modal-body {
  padding: 12px 18px;
}

.loading {
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 100rem;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.2);
}
// @media only screen and (min-width: @screen-width2) and (max-width: @screen-width1) {
//   html {
//     font-size: 0.065vw;
//   }
// }
// @media only screen and (min-width: @screen-width3) and (max-width: @screen-width2) {
//   html {
//     font-size: 0.075vw;
//   }
// }
// @media only screen and (min-width: @screen-width4) and (max-width: @screen-width3) {
//   html {
//     font-size: 0.125vw;
//   }
// }
// @media only screen and (max-width: @screen-width4) {
// html {
//   font-size: 0.288vw;
// }
.main-content {
  padding-bottom: @bottom-nav-height;
  .main-body {
    // background: #0f131c;
    background: url(@/assets/logo/BG.png) no-repeat center center;
    background-size: 100% 100%;
    padding: 10rem;
    overflow-x: hidden;
    padding-top: 0;
    .center-content {
      width: 100%;
    }
  }
}
.tabs-wrap {
  .ant-tabs-nav {
    .ant-tabs-tab {
      margin: 0;
    }
  }
}

.App-H5 {
  .bottom-wrap,
  .header-wrap {
    display: none !important;
  }
  .main-content {
    padding-bottom: 0;
  }
  .main-content {
    .main-body {
      padding: 0;
    }
  }
  .main-content .main-body .center-content {
    height: 100%;
  }
}
// .van-tabs__wrap--scrollable .van-tab--complete {
//   flex: 1 0 auto !important;
// }
// }
</style>

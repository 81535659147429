<template>
  <div class="all">
    <iframe
      id="full_screen"
      class="full_screen"
      ref="iframe"
      :style="newStyle"
      :src="href"
      frameborder="0"
      height="100%"
      width="100%"
      scrolling="no"
    ></iframe>
    <!-- 游戏中金额达到3000+VIP2 -->
    <!-- <tipPops :visible.sync="visible" @handleOk="handletips" /> -->
  </div>
</template>
<script>
import store from "@/store";
import { exitIframe } from "@/handle/RefreshBalance";
export default {
  name: "game",
  props: {
    href: {
      type: String,
      default: "",
    },
    newStyle: {
      type: String,
      default:
        "display: block !important;position: fixed;top: 0;left: 0;width: 100%;height: 100%;border: none;margin: 0;padding: 0;overflow: hidden;z-index: 999;",
    },
  },
  data() {
    return {
      load: 0,
      visible: false,
      game: "",
    };
  },
  components: {
    // tipPops,
  },
  created() {
    let nowRoute = this.$router.currentRoute.path;
    let query = this.$router.currentRoute.query;
    this.$router.push({
      path: nowRoute,
      query: {
        game: "Iframe",
        ...query,
      },
    });
  },
  mounted() {
    this.$refs.iframe.addEventListener("load", () => {
      this.load++;
      if (this.load == 2) {
        /**当VIP==0 账户余额超过100
         * 当VIP等级==2 && 金额产生了变化 && 金额>3000
         * 有弹窗弹窗
         * */
        exitIframe();

        // this.getAmount();
        store.state.gameUrl = "";
        // clearInterval(store.state.Time);
        this.load = 0;
        // this.$store.commit("getRefreshBalance", true);
      }
    });
  },
  methods: {
    /**提示弹窗回调*/
    handletips(val) {
      this.visible = false;
    },
  },
};
</script>
<style lang="less" scoped>
@media only screen and (max-width: @screen-width4) {
  @import "./h5.less";
}
</style>

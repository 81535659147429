module.exports = {
  // 公共
  'Common.payouts': 'Withdraw',
  'Common.deposit': 'Deposit',
  'Common.all': 'Tufts',
  'Common.CopySuccess': 'Copied successfully',
  // Header
  'Header.login': 'Login',
  'Header.registro': 'Register',
  // BottomNav
  'BottomNav.home': 'Start', 
  'BottomNav.share': 'Install', 
  'BottomNav.promotion': 'Promotion', 
  'BottomNav.inTheTank': 'Support', 
  'BottomNav.user-info': 'Profile',
  // Pay
  'Pay.deposit-online': 'Online deposit', 
  'Pay.deposit-money': 'Deposit Amount', 
  'Pay.deposit-prompt': 'Terms of use：After successful payment, the system will add the amount to your account within 1 to 5 minutes.', 
  'Pay.min': 'Minimum', 
  'Pay.max': 'Maximum', 
  'Pay.deposit-submit': 'Recharge Now', 
  'Pay.Extra': 'Extra', 
  // History
  'History.1': 'CONTA',
  'History.2': 'TAXA DE PROGRESSO',
  'History.3': 'DINHEIRO',
  // Withdraw
  'Withdraw.1': 'Amount available for withdrawal',
  'Withdraw.2': 'Account PIX',
  'Withdraw.3': 'Quantity withdrawn',
  'Withdraw.4': 'Extract',
  'Withdraw.5': 'Withdrawal balance is insufficient',
  'Withdraw.6': 'Confirm',
  'Withdraw.7': 'Tips',
  'Withdraw.8': 'Please select the CPF, Phone number, and Email address correctly. The account number must be correct for withdrawals to be made.',
  'Withdraw.9': 'Withdrawal failed. Please check whether the withdrawal account information is correct, or change the withdrawal account and re-initiate the withdrawal.',
  // WithdrawPopups
  'WithdrawPopups.1': 'DICA',
  'WithdrawPopups.2': 'Dear user, you are currently a Black Iron level user and have not unlocked the withdrawal permission. Please recharge any amount to unlock the withdrawal permission before withdrawing.',
  'WithdrawPopups.3': 'Go to deposit',
  'WithdrawPopups.4': 'Dear VIP user, your withdrawal application has been accepted, please pay attention to your bank account balance',
  'WithdrawPopups.5': 'Confirm',
  'WithdrawPopups.6': 'Dear member, this is the first time you have withdrawn money. To familiarize yourself with the withdrawal process on this platform, choose the lowest withdrawal amount of {amount} for withdrawal.',
  'WithdrawPopups.7': 'Go to task',
  'WithdrawPopups.8': 'Dear user, you are invited to participate in the game improvement plan. Please complete the game experience before making withdrawals.',
  'WithdrawPopups.9': 'Go to Settings',
  'WithdrawPopups.10': 'Dear user:<br/>&nbsp;&nbsp;For the safety of your account, please set a withdrawal password and link a unique withdrawal account in time after the first successful withdrawal.<br/>&nbsp;&nbsp;After linking, the game account only supports withdrawals from the linked bank account, effectively protecting the safety of your account funds.',
  'WithdrawPopups.11': 'Dear user, the single withdrawal limit for Bronze level users is {amount}, please select {amount} to withdraw. Upgrading to Silver can unlock more personal withdrawal amounts.',
  'WithdrawPopups.12-1': 'Dear Bronze Level User, the withdrawal rules for your current VIP level are:<br>1. You can withdraw once every 60 hours.<br>2. The withdrawal limit for each withdrawal is {amount}. Please wait ',
  'WithdrawPopups.12-2': ' hours before withdrawing, or you can upgrade to Silver Level.<br><br>Silver Level Withdrawal Rules:<br>1. You can withdraw 5 times a day.<br>2. The withdrawal limit for each withdrawal is R$300<br>',
  'WithdrawPopups.13': 'Upgrade to Silver',
  'WithdrawPopups.14': 'Deposit {amount} to upgrade to Silver',
  'WithdrawPopups.15': 'Congratulations on reaching our highest Incomparable King user level! You only need to top up once, with an amount of {amount} or more, to unlock all Incomparable King benefits. After unlocking, you will enjoy the following privileges:<br/>1. Eliminate all game restrictions<br/>2. Easily withdraw money at any time, with no upper limit on the daily withdrawal amount and number of withdrawals<br/>3. Use Incomparable King\'s exclusive withdrawal channel, and withdraw money instantly<br/>',
  'WithdrawPopups.16': 'Unlock and pay {amount}',
  'WithdrawPopups.17': 'You currently have a withdrawal order being processed. Please wait until the withdrawal order is processed successfully before proceeding with the withdrawal operation. Thanks.',
  'WithdrawPopups.18': 'Linked Accounts',
  'WithdrawPopups.19': 'Respected user:<br/>Please complete the association of the designated withdrawal account, and you can withdraw money normally after success.<br/>Specify the association method for the withdrawal account:<br/>1. Enter the withdrawal account you need to specify<br/>2. Deposit {amount} to activate the designated withdrawal account',
  'WithdrawPopups.20': 'Deposit {amount} <br/> To activate account',
  'pix.1_1': 'Step 1',
  'pix.1_2': 'Step 2',
  'pix.1_3': 'Step 3',
  'pix.1_4': 'Step 4',
  'pix.2': 'Set withdrawal password',
  'pix.3': 'Please set your withdrawal password',
  'pix.4': 'Please enter 6 digit password',
  'pix.5': 'Please remember your withdrawal password after setting it up',
  'pix.6': 'Next step',
  'pix.7': 'Link the only withdrawal account',
  'pix.8': 'Account holder name',
  'pix.9': 'Please enter the account holder\'s name',
  'pix.10': 'Please enter your PIX account',
  'pix.11': 'Step above',
  'pix.12': 'After linking the only withdrawal account, the system only supports game withdrawals from the linked bank account, making your game account funds safer.',
  'pix.13': 'Confirm information',
  'pix.14': 'Withdrawal password:',
  'pix.15': 'Account holder:',
  'pix.16': 'Please check your account information carefully and remember your withdrawal password to ensure the safety of your account funds.',
  'pix.17': 'Verify account & Activate password',
  'pix.18': 'Please activate the withdrawal password and verify the only withdrawal account',
  'pix.19': 'Method:',
  'pix.20': '1. Initiate a deposit of <span style="color: #FEC929;font-size:14rem;">{amount}</span> via the "Activation" button below <br>2. After completing the payment, the system will automatically activate the withdrawal password and verify the withdrawal account',
  'pix.21': 'After the payment is completed, the withdrawal account will be automatically linked and you can enjoy the withdrawal normally.',
  'pix.22': 'Activation',
  'pix.23': 'Order details',
  'pix.24': 'Order amount:',
  'pix.25': 'Amount received:',
  'pix.26': 'Go Pay {amount}',
  // drawcash
  'drawcash.1': 'Add Bank Account',
  'drawcash.name1': 'Account holder name',
  'drawcash.name2': 'Please enter account holder name',
  'drawcash.type': 'PIX key type',
  'drawcash.PIX': 'PIX',
  'drawcash.PIX1': 'Please enter your PIX account',
  'drawcash.CPF': 'CPF',
  'drawcash.CPF1': 'Please enter your CPF',
  'drawcash.tellNum': 'Phone number',
  'drawcash.tellNum1': 'Please enter your phone number',
  'drawcash.confirm': 'confirm',
  // PayModal
  'PayModal.deposit-log': 'Recharge registration', 
  'PayModal.deposit-verify-money': 'Please choose the amount you want to top up',  
  // LeftNav
  'LeftNav.popular': 'Popular', 
  'LeftNav.recently': 'Recente', 
  'LeftNav.favorites': 'Favoritos', 
  // More
  'More.game-more': 'Showing {current} games between {total} {name} games',
  'More.more': 'Load more', 
  //Mine
  'Mine.recover': 'Recover the balance', 
  'Mine.millions-month': 'million monthly', 
  'Mine.account': 'Account',
  'Mine.user-info': 'Profile',
  'Mine.VIP': 'VIP Level',
  'Mine.service': 'Customer service',
  'Mine.language': 'language',
  'Mine.loginOut': 'Log out',
  'Mine.bind': 'Bind account',
  'Mine.loginSuccess': 'Exit successfully',
  'Mine.1': 'Bind login account',
  'Mine.2': 'Set up login account',
  'Mine.3': 'Confirm',
  // Promote
  'Promote.invite': 'To invite',
  // Safety
  'Safety.security-center': 'Security Center', 
  'Security.Withdraw': 'Withdraw',
  'Security.record': 'Withdrawals record',
  // Notice
  'Notice.bonus': 'Suggestion Bonus', 
  'Notice.message-center': 'Message Center',
  'Notice.message': 'News',
  'Notice.notice': 'Notification',
  'Notice.scroll-panel': 'Rolling Panel', 
  // Setting
  'Setting.data': 'Data',
  'Setting.back': 'Turn back',
  'Setting.save': 'To save',
  'Setting.day': 'day', 
  'Setting.month': 'month', 
  'Setting.year': 'year',
  // promote
  'Promote.success': 'Received successfully',
  'Promote.failed': 'Failed to collect',
  'Promote.copy': 'copy',
  'Promote.invite': 'invite',
  'Promote.my': 'My invitation',
  'Promote.Today': 'Today',
  'Promote.All': 'All',
  'Promote.Number': 'Register',
  'Promote.Rewards': 'Rewards',
  'Promote.myRewards': 'My Rewards',
  'Promote.received': 'Received',
  'Promote.receive': 'receive',
  'Promote.collected': 'Available',
  'Promote.media': 'Share to your social media',
  'Promote.friends': 'Share link with your friends',
  // vip
  'Vip.title': 'VIP CLUB',
  'Vip.1': 'Level is unlocked',
  'Vip.2': 'Upgrade to <span style="color: red;">{VIP}</span> to unlock more privileges',
  'Vip.3': 'VIP level to be unlocked',
  'Vip.4': 'Unlock',
  'Vip.5': 'Congratulations, all levels have been unlocked.',
  'vip.0': 'Black Iron',
  'vip.1': 'Bronze',
  'vip.2': 'Silver',
  'vip.3': 'Gold',
  'vip.4': 'Diamond',
  'vip.5': 'Grand Master',
  'vip.6': 'King',
  'vip.7': 'Incomparable King',
  // service
  'Service.service': 'Customer Service',
  'Services.common': 'common problem',
  'Services.How': 'How can I withdraw funds?',
  'Services.withdrawal': 'Dear users, withdrawal is a VIP privilege. Recharge any amount to upgrade to VIP to unlock the withdrawal permission.',
  'Services.1': 'Is there any handling fee for withdrawals?',
  'Services.2': 'We do not charge any withdrawal fees. We believe in providing players with a transparent and fair gaming experience, which includes allowing you to earn bonuses at no additional cost',
  // eventItem1/3
  'EventItem.Receber': 'Receive',
  'EventItem.rewards': 'Complete tasks to get rewards',
  'EventItem.Already': 'Already received',
  'EventItem.1': 'completed',
  'EventItem.2': 'Lucky Slots referral link:',
  'EventItem.3': 'share',
  // eventItem2
  'eventItem2.checkin':'CHECK IN',
  'eventItem2.Complete':'Complete the game once to get the day\'s reward.',
  'eventItem2.Successful':'Check-in successful',
  'eventItem2.failed':'Check-in failed',
  'eventItem2.1':'Novice task',
  'eventItem2.2':'Daily bonus',
  'eventItem2.3':'System tasks',
  // About
  'About.about': 'About',
  'about.1': 'Lucky Slots is a free slot game! We have a rich selection of Vegas-style slot games and offer the widest range of games and the most competitive products on the market. All platforms only access and select internationally renowned online gaming platforms to ensure that every player can play in a fair and credible environment. At the same time, the Costa Rica government will also monitor the game platform data to ensure the fairness and authenticity of the game.Have fun playing games at Lucky Slots!',
  'about.2': 'About Us',
  'about.3': 'Lucky Slots is one of the famous international online casino operators, providing a variety of exciting games, slots, fishing, lottery, sports, etc. Lucky Slots ensures that every player can play in a fair and reliable environment and is committed to providing players with the best gaming experience.',
  //notice
  'notice.1': 'Congratulations to ',
  'notice.2': ' for withdrawing',
  // empty
  'empty.1': 'No games available',
  // limitPops
  'limitPops.1': 'HINT',
  'limitPops.3': 'The system is under maintenance, please stay tuned',
  'limitPops.4': 'Confirm',
  'limitPops.5': 'You are currently a Black Iron user and can only experience Fortune OX games. PG games are only available to Bronze level users.',
  'limitPops.6': 'Play Fortune OX',
  'limitPops.7': 'Go to unlock',
  'limitPops.8': 'Congratulations on getting the R$10 cash gifted by the system,and you can start playing games to make more money immediately.',
  'limitPops.9': 'You are currently a Black Iron level user and can only play Fortune OX games.{gameType} games are only available to {vip} level users.',
  'limitPops.10': 'Recharge {amount} to upgrade to the Incomparable King level and remove all game restrictions.',
  'limitPops.11': 'Go to recharge',
  'limitPops.12': 'The JILI game is only open to Silver level users and can be unlocked by upgrading to Silver.',
  'limitPops.13': 'The PP game is only open to Gold level users and can be unlocked by upgrading to Gold.',
  'limitPops.14': 'The MG game is only open to Diamond level users and can be unlocked by upgrading to Diamond.',
  'limitPops.15': 'The {gameType} game is only open to Grand Master level users and can be unlocked by upgrading to Grand Master.',
  'limitPops.16': 'The {gameType} game is only open to King level users and can be unlocked by upgrading to King.',
  'limitPops.20': 'The {gameType} game is only open to Incomparable King level users and can be unlocked by upgrading to Incomparable King.',
  'limitPops.17': 'Play PG games',
  'limitPops.18': 'Unlock other games',
  'limitPops.19': 'Top up any amount to upgrade to Bronze and unlock all PG games',
  'limitPops.21': '',
  // bottom
  'bottom.1': 'Cassino',
  'bottom.1_1': 'Events',
  'bottom.1_2': 'Bonus',
  'bottom.1_3': 'VIP',
  'bottom.1_4': 'Invite',
  'bottom.2': 'Games',
  'bottom.2_1': 'Slots',
  'bottom.2_2': 'Fishing',
  'bottom.3': 'Support',
  'bottom.3_1': 'Online Support',
  'bottom.4': 'Introduce',
  'bottom.5': 'Others',
  // tipsPop
  'tipsPop.1': 'Congratulations, dear players! Since you have earned more than R$3,000 in total, you have been upgraded to our highest level of Incomparable King user status. Have fun playing!',
  'tipsPop.2': 'Congratulations',
  'tipsPop.3': 'Congratulations, your account balance has exceeded {amount}, go and withdraw money!',
  'tipsPop.4': 'Withdraw money',
  // commonPops新用户登录提示
  'tipsPop.5': 'Dear user, welcome to the casino world of Lucky Slots, register now and get a R$7 bonus!',
  'tipsPop.6': 'Register now',
  // login
  'login.1': 'Register your account',
  'login.9': 'Login',
  'login.2': 'Enter mobile number',
  // 'login.2': 'Enter mobile number/email address',
  'login.3': 'please enter your password',
  'login.4': 'Please re-enter your password',
  'login.5': 'Already have an account? Log in',
  'login.6': 'Don\'t have an account yet? Register account',
  'login.7': 'Register',
  'login.8': 'Login',
  'login.10': 'Or',
}
import {
  request
} from '../request'

/**用户登录 */
export const accountLogin = (param) => request(`/user/login`, 'POST', param);

/** 账户余额 */
export const userMoney = (param) => request(`/user/account`, 'POST', param);

/** 获取上报充值记录 */
export const getFbRechargeRecords = (param) => request(`/v1/green/bus/getFbRechargeRecords`, 'POST', param);

/** 获取充值记录上报完成后，告诉上报结果 */
export const updateFbRechargeRecords = (param) => request(`/v1/green/bus/updateFbRechargeRecords`, 'POST', param);

/** 绑定手机 */
export const bindPhone = (param) => request(`/user/bindPhone`, 'POST', param);

/** 账号绑定 */
export const bindAccount = (param) => request(`user/bindAccount`, 'POST', param);

/** 修改用户名称 */
export const changeName = (param) => request(`/user/updateAppUserNick`, 'POST', param);

/** 用户VIP等级 */
export const userLevel = (param) => request(`/v1/green/bus/accountInfo`, 'POST', param);

/** 用户切换语言显示 */
export const usersetLang = (param) => request(`/user/setLang`, 'POST', param);

/**用户银行卡信息 */
export const userBankInfo = (param) => request(`/user/getUserBankInfo`, 'POST', param);